import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import CountUp from "react-countup";

import { Carousel, Container, Button, Modal } from "react-bootstrap";

// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
// import ParticlesJson from "../images/particles.json";
import { GatsbyImage } from "gatsby-plugin-image";
import Anchor from "../components/elements/Anchor";

import Layout from "../components/common/Layout";
// import Icon from "../components/elements/Icon";
import ContactForm from "../components/common/ContactForm";
import { isBrowser } from "../utilities/checkEnvironment";

// import Video from "../images/180226_A.mp4";

const Homepage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const videoUrl = page.data.video.url;
  const videoRef = useRef(null);
  const headingsRef = useRef(null);
  const [vidHeight, setVidHeight] = useState(0);
  const hoverIcon = page.data.hover_icon;
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const handleCloseImageModal = () => setShowImageModal(false);
  const handleShowImageModal = () => setShowImageModal(true);

  function logit() {
    if (isBrowser) {
      // Targeting Elements
      const scrollY = window.pageYOffset;
      const targetText = document.querySelector(".contents");
      const targetContainer = document.getElementById("content");
      const video = document.querySelector(".vid");
      const targetSection = document.querySelector(".section-1");
      // const navbar = document.querySelector("navbar");
      // On Init
      // const navbarPosition = navbar.getBoundingClientRect();
      const textPosition = targetText.getBoundingClientRect();
      const sectionPosition = targetSection.getBoundingClientRect();

      const sectionHeight = sectionPosition.bottom - 90;
      const distanceFromTop = (sectionHeight - textPosition.height) / 2;
      // targetText.style.bottom = `${distanceFromTop}px`;

      if (sectionHeight > 0) {
        // const navbarPosition = navbar.getBoundingClientRect();
        const textPosition = targetText.getBoundingClientRect();
        const sectionPosition = targetSection.getBoundingClientRect();

        const sectionHeight = sectionPosition.bottom - 90;
        const distanceFromTop = (sectionHeight - textPosition.height) / 2;
        // targetContainer.style.paddingBottom = `${distanceFromTop}px`;
        // targetContainer.style.transform = `translateY(-${distanceFromTop}px)`;
        // targetContainer.style.transform = `translateY(${
        //   (scrollY * 40) / 100
        // }px)`;
        video.style.transform = `translateY(${(scrollY * 40) / 100}px)`;
        targetText.style.bottom = `${distanceFromTop}px`;
        // targetContainer.style.transform = `translateY(${distanceFromTop}px)`;

        // video.style.bottom = `${distanceFromTop}px`;
      }

      // document.addEventListener("scroll", setTextPosition);
      // console.log(distanceFromTop);
    }
    if (isBrowser) {
      const scrollY = window.pageYOffset;
      if (scrollY < videoRef.current.getBoundingClientRect().height) {
        headingsRef.current.style.opacity = `${100 - scrollY * 0.2}%`;
      }
    }
  }

  useEffect(() => {
    setVidHeight(videoRef.current.getBoundingClientRect().height);

    function watchScroll() {
      if (isBrowser) {
        window.addEventListener("scroll", logit);
      }
    }
    watchScroll();
    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", logit);
      }
    };
  });
  // console.log(data);

  return (
    <Layout>
      <div>
        <a id="home" className="anchor" />
      </div>
      {/* Hero Banner */}

      <Container
        id="content"
        fluid
        ref={videoRef}
        className="text-center position-relative"
        style={{ overflow: "hidden" }}
      >
        <video
          loop
          autoPlay
          muted
          className="vid d-none d-sm-block position-absolute start-0"
          // style={{ height: "300px", width: "100vw" }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        <GatsbyImage
          image={page.data.mobile_image.gatsbyImageData}
          alt={page.data.mobile_image.alt}
          className="position-absolute start-0 d-sm-none"
          style={{ width: "100%", height: "100%" }}
        />
        <div className="section-1 d-flex justify-content-center align-items-center position-relative order-10 h-100">
          <div className="overlay" />
          <div
            ref={headingsRef}
            className="contents text-white pb-4 position-absolute hero-headings px-3"
          >
            <h1 className="text-white">{page.data.banner_title.text}</h1>
            <h2 className="text-white mb-4">
              {page.data.banner_subtitle.text}
            </h2>
            <h3 className="text-white mb-4">
              {page.data.banner_subtitle2.text}
            </h3>
            <h3 className="text-white">{page.data.banner_subtitle3.text}</h3>
          </div>
        </div>
      </Container>

      {/* Sub Banner Section */}
      <Container fluid className="px-3 my-5">
        <a className="anchor" id="company" />
        <div className="text-center pb-1 px-3 strong-blue">
          <h2>
            <PrismicRichText field={page.data.heading1.richText} />
          </h2>
        </div>
        <hr className="mx-auto" style={{ maxWidth: "5%" }} />
        <div className="container fluid text-center mt-5">
          <div className="row gy-3">
            {page.data.icon_box.map((item, index) => {
              return (
                <div key={index} className="col-md-4">
                  <div>
                    <Anchor href={item.link.url}>
                      <GatsbyImage
                        image={item.icon.gatsbyImageData}
                        alt={item.icon.alt}
                        className="iconStyle"
                      />
                    </Anchor>
                  </div>
                  <div className="text-center" style={{ padding: "0 10px" }}>
                    <div
                      className="text-decoration-strong my-4"
                      style={{ fontWeight: 700, fontSize: "24px" }}
                    >
                      {item.icon_title.text}
                    </div>
                    <span>{item.icon_subtitle.text}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>

      {/* Matrix Video */}
      <Container fluid className="my-5 text-center position-relative ">
        <video
          loop
          autoPlay
          muted
          className="d-none d-sm-block position-absolute start-0 background-video"
          // style={{ height: "300px", width: "100vw" }}
        >
          <source src={page.data.background_video_2.url} type="video/mp4" />
        </video>
        <GatsbyImage
          image={page.data.mobile_image2.gatsbyImageData}
          alt={page.data.mobile_image2.alt}
          className="position-absolute start-0 d-sm-none"
          style={{ width: "100%", height: "100%", zIndex: -1 }}
        />
        <div
          className="row gy-3 justify-content-center"
          style={{ padding: "6rem 0" }}
        >
          {page.data.matrix_icon_box.map((item, index) => {
            return (
              <div key={index} className="col-md-3 text-white">
                <div className=" mb-2">
                  <Anchor href="/">
                    <GatsbyImage
                      image={item.icon_box1.gatsbyImageData}
                      alt={item.icon_box1.alt}
                      className="iconStyle filter-white"
                      style={{ color: "white" }}
                    />
                  </Anchor>
                </div>
                <CountUp
                  start={0}
                  end={item.number_title.text}
                  duration={1.5}
                  decimals={0}
                  enableScrollSpy
                  scrollSpyOnce
                >
                  {({ countUpRef }) => (
                    <div
                      className="counter text-white"
                      style={{ fontSize: "42px" }}
                    >
                      <span ref={countUpRef} />+
                    </div>
                  )}
                </CountUp>

                <h5 className="text-white mt-3 counter-border">
                  {item.title.text}
                </h5>
              </div>
            );
          })}
        </div>
      </Container>
      {/* End of Matrix Section */}

      {/* Software Section */}
      <Container className="p-0 my-5">
        <a className="anchor" id="software" />
        <div className="text-center">
          <h2>{page.data.heading2.text}</h2>
          <p className="my-4">{page.data.subheading2.text}</p>
        </div>
        <hr className="mx-auto" style={{ maxWidth: "5%" }} />
        <div className="row p-1 text-center justify-content-center">
          {page.data.carousel.map((item, index) => {
            return (
              <div key={index} className="col-8 col-md-6 px-3">
                <Carousel
                  fade
                  variant="dark"
                  indicators
                  controls={false}
                  style={{ height: "100%" }}
                  className="container-carousel"
                >
                  <Carousel.Item
                    interval={4000}
                    onClick={() => {
                      if (!item.link.url) handleShowImageModal(true);
                      setModalImage(item.carousel_item.gatsbyImageData);
                    }}
                  >
                    <Anchor href={item.link.url}>
                      <GatsbyImage
                        image={item.carousel_item.gatsbyImageData}
                        alt={item.carousel_item.alt}
                        style={{
                          color: "white",
                          width: "auto",
                          objectFit: "contain",
                        }}
                        className="mx-auto carousel-icons vertical-align-center"
                      />
                      <div className="hover-icon">
                        <GatsbyImage
                          image={hoverIcon.gatsbyImageData}
                          alt={hoverIcon.alt}
                        />
                      </div>
                    </Anchor>
                  </Carousel.Item>
                  <Carousel.Item
                    interval={4000}
                    onClick={() => {
                      if (!item.link.url) handleShowImageModal(true);
                      setModalImage(item.carousel_item2.gatsbyImageData);
                    }}
                  >
                    <Anchor href={item.link.url}>
                      <GatsbyImage
                        image={item.carousel_item2.gatsbyImageData}
                        alt={item.carousel_item2.alt}
                        style={{ color: "white", width: "auto" }}
                        className="mx-auto carousel-icons"
                      />
                      <div className="hover-icon">
                        <GatsbyImage
                          image={hoverIcon.gatsbyImageData}
                          alt={hoverIcon.alt}
                        />
                      </div>
                    </Anchor>
                  </Carousel.Item>
                </Carousel>
                <div className="text-center">
                  <PrismicRichText field={item.carousel_subheading.richText} />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
      {/* End of Software Section */}
      <hr
        className="mx-auto"
        style={{
          height: "2px",
          borderWidth: 0,
          color: "gray",
          backgroundColor: "gray",
          maxWidth: "25%",
        }}
      />
      {/* Hardware Section */}
      <Container fluid className="px-3 my-5 text-center">
        <a className="anchor" id="hardware" />
        <h2>{page.data.heading3.text}</h2>
        <h5 className="my-4">{page.data.subheading3.text}</h5>

        <Button
          type="button"
          href={page.data.button_link.url}
          variant="info"
          className="button"
        >
          {page.data.button_label}
        </Button>
        <hr className="mx-auto mt-5" style={{ maxWidth: "5%" }} />
      </Container>

      <Container className="px-3 my-5 text-center">
        <div className="row p-1 justify-content-center text-center">
          {page.data.image_with_text.map((item, index) => {
            return (
              <div key={index} className="col-8 col-md-4">
                <Carousel fade variant="dark" indicators controls={false}>
                  <Carousel.Item className="position-relative">
                    <Anchor href={item.link.url}>
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.image.alt}
                        style={{ color: "white" }}
                        className="mx-auto"
                      />
                      <div className="hover-icon">
                        <GatsbyImage
                          image={hoverIcon.gatsbyImageData}
                          alt={hoverIcon.alt}
                        />
                      </div>
                    </Anchor>
                  </Carousel.Item>
                  {item.imag2.gatsbyImageData && (
                    <Carousel.Item className="position-relative">
                      <Anchor href={item.link.url}>
                        <GatsbyImage
                          image={item.imag2.gatsbyImageData}
                          alt={item.imag2.alt}
                          style={{ color: "white" }}
                          className="mx-auto"
                        />

                        <div className="hover-icon">
                          <GatsbyImage
                            image={hoverIcon.gatsbyImageData}
                            alt={hoverIcon.alt}
                          />
                        </div>
                      </Anchor>
                    </Carousel.Item>
                  )}
                </Carousel>
                <h3>{item.heading.text}</h3>
                <div className="text-center">
                  <PrismicRichText field={item.description.richText} />
                </div>
              </div>
            );
          })}
        </div>
        <Anchor href={page.data.button2_link.url}>
          <Button type="button" variant="info" className="mt-5 button">
            {page.data.button2_label}
          </Button>
        </Anchor>
      </Container>
      {/* End of Hardware Section */}
      <hr
        className="mx-auto"
        style={{
          height: "2px",
          borderWidth: 0,
          color: "gray",
          backgroundColor: "gray",
          maxWidth: "25%",
        }}
      />
      {/* Partners Section */}
      <div className="text-center my-5">
        <h2>{page.data.heading4.text}</h2>
      </div>
      <hr className="mx-auto mt-5" style={{ maxWidth: "5%" }} />
      <Container className="my-5 px-2">
        <div className="row text-center g-1">
          {page.data.image_group.map((item, index) => {
            return (
              <div key={index} className="col-md-3">
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  alt={item.image.alt}
                  style={{ color: "white" }}
                  className="mx-auto"
                />
              </div>
            );
          })}
        </div>
      </Container>
      {/* End of Partners Section */}

      {/* Bottom About Us Video Section */}
      <Container
        fluid
        className="position-relative my-5 d-flex justify-content-center align-items-center"
        style={{ height: "fit-content", minHeight: "60vh" }}
      >
        <video
          loop
          autoPlay
          muted
          className="d-none d-sm-block position-absolute start-0 background-video"
          style={{ height: "100%" }}
          // style={{ height: "300px", width: "100vw" }}
        >
          <source src={page.data.background_video_3.url} type="video/mp4" />
        </video>
        <GatsbyImage
          image={page.data.mobile_image3.gatsbyImageData}
          alt={page.data.mobile_image3.alt}
          className="position-absolute start-0 d-sm-none"
          style={{ width: "100%", height: "100%" }}
        />
        <div className="about-us-overlay" />
        <div
          className="wrapper-div position-relative"
          style={{ zIndex: "100" }}
        >
          <div
            className="text-center text-white pt-4 pb-1 px-3 strong-blue"
            // style={{ fontSize: "2rem" }}
          >
            <h2 className="text-white">
              <PrismicRichText field={page.data.slider_heading.richText} />
            </h2>
          </div>
          <hr
            className="mx-auto mt-3"
            style={{ maxWidth: "5%", color: "grey" }}
          />
          <div className="row justify-content-center text-center py-3">
            <div className="col-8 text-center text-white">
              <Carousel indicators controls={false}>
                {page.data.dark_slider.map((item, index) => {
                  return (
                    <Carousel.Item
                      className="pb-5"
                      key={index}
                      style={{ fontSize: "20px" }}
                    >
                      <PrismicRichText field={item.description.richText} />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </Container>
      {/* End of About Us Video Section */}

      {/* Contact Section */}
      <hr
        className="mx-auto"
        style={{
          height: "2px",
          borderWidth: 0,
          color: "gray",
          backgroundColor: "gray",
          maxWidth: "25%",
        }}
      />
      <div className="text-center my-5">
        <a className="anchor" id="contact" />
        <h2>{page.data.heading5.text}</h2>
      </div>
      <hr className="mx-auto mb-5" style={{ maxWidth: "5%" }} />
      <Container className="my-5">
        <div
          className="row mb-5 text-center justify-content-center align-items-center"
          style={{ gap: "4rem" }}
        >
          {page.data.contact_icon.map((item, index) => {
            return (
              <div
                key={index}
                className="col-8 col-sm-4 col-xxl-2 border rounded position-relative"
                style={{
                  padding: "50px 20px",
                  height: "170px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Anchor href={item.link.url} target="_blank">
                    <GatsbyImage
                      image={item.icon.gatsbyImageData}
                      alt={item.icon.alt}
                      className="iconStyle contact-icons"
                    />
                  </Anchor>
                  <div className="link-style" style={{ marginTop: "auto" }}>
                    <h4>{item.heading.text}</h4>
                    <a href={item.link.url}>{item.link_label}</a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Container>
          <div className="row justify-content-center g-1">
            <div className="col-11 col-md-6">
              <ContactForm />
            </div>
            <div className="col-11 col-md-6">
              <iframe
                src={page.data.map_url}
                style={{ border: 0, minHeight: "300px" }}
                className="map"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="map"
              />
            </div>
          </div>
        </Container>
      </Container>
      <Modal show={showImageModal} onHide={handleCloseImageModal} size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          {modalImage && <GatsbyImage image={modalImage} alt="project image" />}
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        banner_subtitle3 {
          text
        }
        banner_subtitle {
          text
        }
        banner_subtitle2 {
          text
        }
        banner_title {
          text
        }
        mobile_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        mobile_image2 {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        mobile_image3 {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        heading1 {
          richText
        }
        icon_box {
          icon {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          icon_subtitle {
            text
          }
          icon_title {
            text
          }
          link {
            url
          }
        }
        video {
          url
        }
        background_video_2 {
          url
        }
        background_video_3 {
          url
        }
        matrix_icon_box {
          icon_box1 {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          number_title {
            text
          }
          title {
            text
          }
        }
        button2_label
        button2_link {
          url
        }
        button_label
        button_link {
          url
        }
        carousel {
          carousel_item {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          carousel_item2 {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          carousel_subheading {
            richText
          }
          link {
            url
          }
        }
        hover_icon {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        contact_icon {
          heading {
            text
          }
          icon {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          link {
            url
          }
          link_label
        }
        dark_slider {
          description {
            richText
          }
        }
        heading2 {
          text
        }
        heading3 {
          text
        }
        heading4 {
          text
        }
        heading5 {
          text
        }
        map_url
        image_group {
          image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
        image_with_text {
          description {
            richText
          }
          heading {
            text
          }
          imag2 {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          link {
            url
          }
        }
        slider_heading {
          richText
        }
        subheading2 {
          text
        }
        subheading3 {
          text
        }

        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
      }
    }
  }
`;

export default Homepage;
